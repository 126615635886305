<template>
  <div class="EntityInfo ">
    <div class="box padbm_10">
      <div class="position_relative">
        <el-carousel height="200px" arrow="never" >
          <el-carousel-item v-for="(item, index) in onlineEntityInfo.PhotoURLList" :key="index">
            <el-image class="" style="width: 100%; height: 100%" :src="item.PhotoURL" fit="cover" ></el-image>
          </el-carousel-item>
        </el-carousel>

        <el-image class="logo radius5" style="width: 45px; height: 45px;z-index:9" fit="cover" :src="onlineEntityInfo.EntityLogoURL" ></el-image>
      </div>
      <div v-if="StoreShowInfo.some((val)=>{return val == 'Name'})" class="title font_18 color_333 pad_0_10 martp_10">{{onlineEntityInfo.EntityName}}</div>

      <div class="martp_5 pad_0_10">
        <el-image style="width: 11px; height: 11px" :src=" require('../../../../assets/img/store/time@2x.png')"
        ></el-image>
        <span class="font_14 color_666 marlt_5">营业时间 {{formatBusinessDate()}} {{onlineEntityInfo.BusinessStartTime}} -- {{onlineEntityInfo.BusinessEndTime}}</span>
      </div>

      <div v-if="StoreShowInfo.some((val)=>{return val == 'Phone'})" class="dis_flex flex_x_between flex_y_center martp_5 pad_0_10">
        <div>
          <el-image  style="width: 11px; height: 11px"  :src=" require('../../../../assets/img/store/dh@2x.png') "  ></el-image>
          <span class="font_14 color_666 marlt_5" >服务电话：{{onlineEntityInfo.ServiceTelephoneNumber}}</span >
        </div>
        <div class="line dis_flex flex_y_center pad_0_10">
          <el-image style="width: 11px; height: 15px" :src="require('../../../../assets/img/store/phone.png') " ></el-image>
        </div>
      </div>

      <div v-if="StoreShowInfo.some((val)=>{return val == 'Address'})" class="dis_flex flex_x_between flex_y_center martp_5 pad_0_10">
        <div>
          <el-image  style="width: 9px; height: 12px" :src="require('../../../../assets/img/store/dz@2x.png') " ></el-image>
          <span class="font_14 color_666 marlt_5" >门店地址：{{onlineEntityInfo.AddressDetail}}</span >
        </div>
        <div class="line dis_flex flex_y_center pad_0_10">
          <el-image  style="width: 11px; height: 16px" :src="require('../../../../assets/img/store/wz@2x.png') "  ></el-image>
        </div>
      </div>
      <div v-if="StoreShowInfo.some((val)=>{return val == 'Describe'})"  class="font_14 color_666 martp_5 pad_0_10">简介:{{onlineEntityInfo.Description}}</div>
    </div>
    
  </div>
</template>

<script>
import API from "@/api/Marketing/EShopManage/EShopInfo";
var Enumerable = require("linq");
export default {
 name: 'EntityInfo',
  props:{
    StoreShowInfo:{
      type:Array,
      default:()=>{
        return ['Name','Address','Phone','Describe'];
      }
    }
  },
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      onlineEntityInfo:"",
    }
  },
   /**计算属性  */
  computed: {
  },
  /**  方法集合  */
  methods: {
     /**  获取网店信息  */
    getOnlineEntityInfo(){
      var that = this;
      that.loading = true;
      API.getOnlineEntityInfo()
      .then((res) => {
        if (res.StateCode == 200) {
          that.onlineEntityInfo = res.Data;
          that.$emit("getEntityName",that.onlineEntityInfo.EntityName);
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      })
      .finally(function () {
        that.loading = false;
      });
    }, 


    /**  处理营业时间  */
    formatBusinessDate(){
      let that = this;
      let businessWeekStr = "";
      var arr = Enumerable.from(that.onlineEntityInfo.EntityBusinessDate).select(val=>(val.Data)).toArray();
      var result = [];
      var tmp;
      while(tmp = arr.shift()){
        if(result.length == 0){
          result.push([tmp]);
          continue;
        }
        var e = result[result.length - 1];
        if(tmp == e[e.length - 1] + 1){
            e.push(tmp);
        }else{
            result.push([tmp]);
        }
      } 
      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        if (element.length >= 2) {
          businessWeekStr = businessWeekStr + that.toWeekName(element[0]-1) + "至" + that.toWeekName(element[element.length - 1]-1) + "、";
        }
        if (element.length == 1) {
          businessWeekStr = businessWeekStr + that.toWeekName(element[0] - 1) + "、";
          
        }
      }

      return businessWeekStr.slice(0,businessWeekStr.length - 1);
    },
     /**  将数字转换成周几  */
    toWeekName(num) {
      let weekday = new Array(7);
      weekday[0] = "周一";
      weekday[1] = "周二";
      weekday[2] = "周三";
      weekday[3] = "周四";
      weekday[4] = "周五";
      weekday[5] = "周六";
      weekday[6] = "周日";
      return weekday[num];
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {
    let that = this
    that.getOnlineEntityInfo()
  },
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {},
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
}
</script>

<style lang="scss">

.EntityInfo{
  

 }
</style>
