// 网点信息
import * as API from '@/api/index'
export default {
    /**  上传图片*/
    getOnlineEntityInfo: params => {
        return API.POST('api/onlineEntity/list', params)
    },
     /**  门店信息更新*/
     updateOnlineEntity: params => {
      return API.POST('api/onlineEntity/updateOnlineEntity', params)
  },
}